<template>
  <div class="dealOrder">
    <div class="dealOrderTitle">
      <div class="myDealOrder">
        <p>我的订单</p>
      </div>
    </div>
    <el-table :data="tableData" border>
      <el-table-column prop="orderCode" label="订单编号"></el-table-column>
      <el-table-column prop="consigneeName" label="收货人姓名"></el-table-column>
      <el-table-column prop="orderPrice" label="订单价格"></el-table-column>
      <el-table-column prop="orderStatus" label="订单状态"></el-table-column>
      <el-table-column prop="orderTime" label="创建时间"></el-table-column>
      <el-table-column prop="paymentTime" label="支付时间"></el-table-column>
      <el-table-column prop="paymentType" label="支付类型"></el-table-column>
    </el-table>
    <el-row class="pagination">
      <el-col :span="24">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        ></el-pagination>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [
        {
          orderCode: "002261729",
          consigneeName: "张明",
          orderPrice: "￥21",
          orderStatus: "已支付",
          orderTime: "2020-03-06 12:30:13",
          paymentTime: "2020-03-06 12:32:16",
          paymentType: "微信支付"
        }
      ],
      current: 1,
      pageSize: 10,
      totalPage: null
    };
  },
  mounted() {
    // this.getDealDatas();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDealDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDealDatas();
    },
    getDealDatas() {
      protocolFwd.param_queryTradeNote.param.page = this.current - 1;
      protocolFwd.param_queryTradeNote.param.size = this.pageSize;
      http.postFront(protocolFwd.param_queryTradeNote).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          const datas = value.content;
          for (let i = 0; i < datas.length; i++) {
            if (datas[i].bsFlag == "B") {
              datas[i].bsFlagName = "买入";
            } else if (datas[i].bsFlag == "S") {
              datas[i].bsFlagName = "卖出";
            }
            if (datas[i].createTime) {
              datas[i].createTimeFormat = global.util.DateFormate(
                datas[i].createTime
              );
            } else {
              datas[i].createTimeFormat = "--";
            }
          }
          this.tableData = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    viewDetails(item) {
      this.orderDetails = item;
      this.dialogVisible = true;
    }
  }
};
</script>
<style scoped>
.dealOrder {
  padding: 0 15px;
}
.dealOrderTitle {
  overflow: hidden;
}
.dealOrder .myDealOrder {
  float: left;
  line-height: 40px;
}
.dealOrderTitle .myDealOrder p {
  font-size: 16px;
}
.dealOrder .pagination {
  margin-top: 15px;
}
</style>
